import React from 'react';
import '../style/guest.css';

export const Field = (props) => {
  if (props.type && props.type === 'radio') {
    return (
      <div className="input--radio">
        <div className="input--heading">{props.label}</div>
        {props.options.map((option, index) => {
          return (
            <div key={index} className="input--wrapper input--radio--wrapper">
              <input
                type="radio"
                id={option.name}
                name={props.name}
                value={option.value}
                checked={option.value === props.value}
                onChange={props.onChange}
              />
              <label htmlFor={option.name}>{option.label}</label>
            </div>
          );
        })}
        {props.error && <div className={'input--error'}>{props.error}</div>}
      </div>
    );
  }
  return (
    <div className="input">
      <div className="input--wrapper">
        <input
          type={props.type || 'text'}
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={props.onChange}
        />
        <label className="input--heading" htmlFor={props.name}>
          {props.label}
          {props.explain && (
            <small style={{ display: 'block', fontWeight: 'normal' }}>
              <i>{props.explain}</i>
            </small>
          )}
        </label>
      </div>
      {props.error && <div className={'input--error'}>{props.error}</div>}
    </div>
  );
};
