const config = {
    apiKey: "AIzaSyDaUotJYoJ-SfVAAtzsdMd61X-fTb5ku_g",
    authDomain: "bruiloft-8b231.firebaseapp.com",
    databaseURL: "https://bruiloft-8b231.firebaseio.com",
    projectId: "bruiloft-8b231",
    storageBucket: "bruiloft-8b231.appspot.com",
    messagingSenderId: "384481785900",
    appId: "1:384481785900:web:f76087c63346307da6b989",
    measurementId: "G-F1B20G58WY"
  }
  
  export default config;