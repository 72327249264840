import React from 'react';
import '../style/guest.css';
import History, { pathWithId } from './History';
import paths from '../config/paths';
import { _RSVP_OPTIONS } from '../global';
import { getSaluteName } from './Guest';

export const EveningGuestRsvpAnswered = ({ ...props }) => {
  return (
    <div className="guest-main-wrapper">
      <div className="save-the-date">
        <div className="save-the-date--salutation fade-in delay-2">
          <span className="serif save-the-date--guest">{getSaluteName(props.guest)}</span>
        </div>
        <div className="delay-4 fade-in">
          <Message rsvp={props.guest.rsvp} />
        </div>
        {props.guest.rsvp !== _RSVP_OPTIONS.none && (
          <>
            <div className={'delay-6 fade-in'}>Tot dan:</div>
            <div className="save-the-date--us serif delay-8 fade-in">Paul & Denise</div>
            <div className="save-the-date--date delay-10 fade-in">
              <span className="serif">20 April</span>
              <br />
              <span className="serif">2024</span>
            </div>
          </>
        )}

        <div className="btn--wrapper delay-12 fade-in">
          <p>Toch iets wijzigen? dat kan:</p>
          <button
            className="btn btn--primary"
            type="button"
            onClick={() => History.push(pathWithId(paths.guest.children.rsvp.path, props.match.params.id))}
          >
            Pas aan
          </button>
        </div>
      </div>
    </div>
  );
};

const Message = ({ ...props }) => {
  switch (props.rsvp) {
    case _RSVP_OPTIONS.evening:
      return (
        <div>
          <p>Tof! Je hebt aangegeven dat je erbij bent.</p>
        </div>
      );
    case _RSVP_OPTIONS.none:
      return (
        <div>
          <p>Jammer! Je hebt aangegeven dat je er helaas niet bij kan zijn.</p>
        </div>
      );
    default:
      return null;
  }
};
