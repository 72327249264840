import { createBrowserHistory } from 'history';

export default createBrowserHistory();

export const pathWithId = (path, id) => {
  return path.replace(':id', id);
};

export const isAdmin = (id) => {
  if (!id) {
    return false;
  }
  return ['j04MpCDvAJx4smAXOEAN'].includes(id);
};
