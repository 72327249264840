import React from 'react';
import '../style/menu.css';
import History, { isAdmin, pathWithId } from './History';
import paths from '../config/paths';

export const Menu = ({ id, isEvening }) => {
  const navigate = (event, path) => {
    event.preventDefault();
    History.push(pathWithId(path, id));
    document.body.classList.add('nav-closed');
  };
  return (
    <>
      <div
        className="nav-toggle"
        onClick={() => {
          const bodyClasses = document.body.classList;
          if (!bodyClasses.contains('nav-open')) {
            bodyClasses.add('nav-open');
            return;
          }
          bodyClasses.toggle('nav-closed');
        }}
      >
        <div className="hamburger" />
        <div className="hamburger" />
        <div className="hamburger" />
      </div>
      <div className="nav-list">
        <div className="nav-list--item">
          <a href="/home" onClick={(e) => navigate(e, paths.guest.path)}>
            Home
          </a>
        </div>
        <div className="nav-list--item">
          <a href="/Location" onClick={(e) => navigate(e, paths.guest.children.info.children.location.path)}>
            Locatie
          </a>
        </div>
        <div className="nav-list--item">
          <a href="/Tijden" onClick={(e) => navigate(e, paths.guest.children.info.children.schedule.path)}>
            {isEvening ? 'Tijden' : 'Programma'}
          </a>
        </div>
        <div className="nav-list--item">
          <a href="/praktische-info" onClick={(e) => navigate(e, paths.guest.children.info.children.practical.path)}>
            Praktische Info
          </a>
        </div>
        {isAdmin(id) && (
          <div className="nav-list--item">
            <a href="/Admin" onClick={(e) => navigate(e, paths.guest.children.admin.path)}>
              Admin
            </a>
          </div>
        )}
      </div>
    </>
  );
};
