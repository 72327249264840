import React from 'react';
import '../style/guest.css';
import history, { pathWithId } from './History';
import paths from '../config/paths';
import { getSaluteName } from './Guest';

export const EveningGuestSaveDate = ({ ...props }) => {
  return (
    <div className="guest-main-wrapper">
      <div className="save-the-date delay-0 fade-in">
        <div className="save-the-date--salutation delay-2 fade-in">
          <span className="serif save-the-date--guest">Lieve {getSaluteName(props.guest)}</span>
        </div>
        <div className="save-the-date--text delay-4 fade-in">
          We gaan eindelijk vieren dat we getrouwd zijn! In de avond is er feest en daar willen we jou graag bij hebben!
        </div>
        <div className="save-the-date--us serif delay-6 fade-in">Paul & Denise</div>
        <div className="save-the-date--date delay-8 fade-in">
          <span className="serif">20 April</span>
          <br />
          <span className="serif">2024</span>
        </div>
        <div className="save-the-date--text delay-10 fade-in">Laat even weten of je komt:</div>
        <div className="btn--wrapper delay-12 fade-in">
          <button
            className="btn btn--primary"
            type="button"
            onClick={() => history.push(pathWithId(paths.guest.children.rsvp.path, props.match.params.id))}
          >
            RSVP
          </button>
        </div>
      </div>
    </div>
  );
};
