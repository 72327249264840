import React from 'react';
import { useSanityContent } from '../hooks/useSanityContent';
import '../style/content.css';
import paths from '../config/paths';
import { InfoLayout } from './InfoLayout';
import { Route, Switch, Redirect } from 'react-router-dom';

export const Info = ({ guest }) => {
  const [result, loading] = useSanityContent();
  React.useEffect(() => {
    document.body.classList.add('content-view');
    document.body.classList.remove('content-view-out');
    return () => {
      document.body.classList.add('content-view-out');
    };
  }, []);
  if (loading || result.length === 0) {
    return null;
  }
  const scheduleId =
    guest.type === 'day' ? '02711d3b-eee2-442e-b013-4c3416f81972' : '542bd1f1-aea3-411a-9419-880ca2effae7';
  const locationId =
    guest.type === 'day' ? 'c1878838-4d96-480f-bfec-0181aab4f88c' : 'e0b9eb03-bfc1-4698-95e9-8643eeb3e795';
  return (
    <Switch>
      <Route
        exact={true}
        path={paths.guest.children.info.children.location.path}
        render={() => (
          <InfoLayout guest={guest} sanityData={result.find((item) => item._id === locationId)} type="location" />
        )}
      />
      <Route
        exact={true}
        path={paths.guest.children.info.children.schedule.path}
        render={() => (
          <InfoLayout guest={guest} sanityData={result.find((item) => item._id === scheduleId)} type="schedule" />
        )}
      />
      <Route
        exact={true}
        path={paths.guest.children.info.children.practical.path}
        render={() => (
          <InfoLayout
            guest={guest}
            sanityData={result.find((item) => item._id === '530063ac-b893-48dd-a954-4057c9598974')}
          />
        )}
      />
      <Redirect to={paths.guest.children.info.children.location.path} />
    </Switch>
  );
};
