import React from 'react';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/firebase-firestore';
import '../style/normalize.css';
import '../style/app.css';
import { Guest } from './Guest';
import { Router, Route } from 'react-router';
import History from './History';
import config from '../config/firebase';
import leaves from '../images/leaves-bg-high.png';
import leftTop from '../images/left-top-high.png';
import rightBottom from '../images/right-bottom-high.png';
import polygonInner from '../images/polygon-inner.svg';
import polygonOuter from '../images/polygon-outer.svg';
import paths from '../config/paths';

export class App extends React.Component {
  constructor(props) {
    super(props);
    firebase.initializeApp(config);
    this.state = {
      loading: true,
    };
  }

  toggleLoading() {
    this.setState({
      loading: !this.state.loading,
    });
  }

  render() {
    return (
      <div className={'app-main-wrapper' + (this.state.loading ? ' is-loading' : '')}>
        <div className={'loading-wrapper'} style={{ backgroundImage: `url(${leaves})` }}>
          <img className="polygon-inner" src={polygonInner} alt="pol" />
          <img className="polygon-outer" src={polygonOuter} alt="pol" />
        </div>
        <div className="content-view--bg">
          <div className="content-view--bg-left-top" style={{ backgroundImage: `url(${leftTop})` }}></div>
          <div className="content-view--bg-right-bottom" style={{ backgroundImage: `url(${rightBottom})` }}></div>
          <div className="content-view--bg-frame"></div>
        </div>
        <div className={'route-wrapper'}>
          <Router history={History}>
            <Route
              path={paths.guest.path}
              render={(props) => (
                <Guest {...props} isLoading={this.state.loading} toggleLoading={() => this.toggleLoading()} />
              )}
            />
          </Router>
        </div>
      </div>
    );
  }
}
