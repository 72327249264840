const guestBase = '/guest/:id';

const paths = {
  guest: {
    path: guestBase,
    children: {
      saveTheDate: {
        path: `${guestBase}/save-the-date`,
      },
      rsvp: {
        path: `${guestBase}/rsvp`,
      },
      info: {
        path: `${guestBase}/info`,
        children: {
          location: {
            path: `${guestBase}/info/locatie`,
          },
          schedule: {
            path: `${guestBase}/info/tijden`,
          },
          practical: {
            path: `${guestBase}/info/praktisch`,
          },
        },
      },
      admin: {
        path: `${guestBase}/admin`,
      },
    },
  },
};

export default paths;
