import React from 'react';
import '../style/content.css';
import { Admin } from './admin';
import { isAdmin } from './History';

export const AdminGate = ({ guest }) => {
  React.useEffect(() => {
    document.body.classList.add('content-view');
    document.body.classList.remove('content-view-out');
    return () => {
      document.body.classList.add('content-view-out');
    };
  }, []);

  if (!isAdmin(guest.id)) {
    return (
      <div className="info-layout" style={{ position: 'relative', zIndex: '500' }}>
        Nothing to see here, move along.
      </div>
    );
  }
  return <Admin />;
};
