import React from 'react';
import '../style/content.css';
// https://github.com/sanity-io/block-content-to-react
import BlockContent from '@sanity/block-content-to-react';
import { urlFor } from '../util/ImageBuilder';
const serializers = {
  types: {
    mainImage: (props) => (
      <span style={{ display: 'block' }}>
        <img src={urlFor(props.node.asset).width(600).url()} alt="" />
      </span>
    ),
    math: (props) => <span>{console.log(props)}</span>,
  },
};

export const InfoLayout = ({ sanityData, type }) => {
  return (
    <div className="info-layout">
      <h1 className="serif delay-2 fade-in text--center">{sanityData.title}</h1>
      <BlockContent
        className={'delay-6 fade-in info-layout--content mb-8'}
        blocks={sanityData.body}
        serializers={serializers}
      />
      {type === 'location' && (
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2410.0011111631657!2d4.857734377264114!3d52.84036501246086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47cf4dde8a8e780d%3A0xbf2322d610cd4a48!2sDe%20Waterkroon!5e0!3m2!1sen!2snl!4v1703012948175!5m2!1sen!2snl"
          width="800"
          height="300"
          style={{ border: 0, maxWidth: '100%', marginTop: '2rem' }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      )}
    </div>
  );
};
