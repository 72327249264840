import cn from 'classnames';
import firebase from 'firebase/app';
import React from 'react';
import { _RSVP_OPTIONS } from '../global';
import '../style/content.css';
import { AddGuestForm } from './AddGuestForm';
import { getSaluteName } from './Guest';
import { isAdmin } from './History';

export const Admin = () => {
  const storedGuest = sessionStorage.getItem('guest_list');
  const [guestList, setGuestList] = React.useState(JSON.parse(storedGuest || '[]'));
  React.useEffect(() => {
    if (storedGuest !== null) {
      return;
    }
    const db = firebase.firestore();
    db.collection('guest')
      .get()
      .then((resp) => {
        const resolvedDocs =
          resp.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          }) || [];
        setGuestList(resolvedDocs);
        sessionStorage.setItem('guest_list', JSON.stringify(resolvedDocs));
      });
  }, [storedGuest]);

  if (!guestList.length) {
    return null;
  }
  const headCounter = (guest) => {
    const { name, has_plus_one } = guest;
    let nameList = name.split('&');
    if (has_plus_one) {
      nameList = nameList.concat(has_plus_one.split(','));
    }
    return nameList.length;
  };
  const guests = guestList.filter((guest) => !isAdmin(guest.id));
  const dayGuests = guests.filter((guest) => guest.type === 'day');
  const dayGuestsRsvp = dayGuests.filter((guest) => guest.rsvp === _RSVP_OPTIONS.fullDay);
  const headcountTotalDay = dayGuests
    .map(headCounter)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  const headcountRsvpTotalDay = dayGuestsRsvp
    .map(headCounter)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const eveningGuests = guests.filter((guest) => guest.type === 'evening');
  const eveningGuestsRsvp = eveningGuests.filter((guest) => guest.rsvp === _RSVP_OPTIONS.evening);
  const headcountTotalEvening =
    eveningGuests.length === 0
      ? 0
      : eveningGuests.map(headCounter).reduce((previousValue, currentValue) => previousValue + currentValue);
  const headcountRsvpTotalEvening =
    eveningGuestsRsvp.length === 0
      ? 0
      : eveningGuestsRsvp.map(headCounter).reduce((previousValue, currentValue) => previousValue + currentValue);
  return (
    <div className="info-layout" style={{ position: 'relative', zIndex: '13', maxWidth: '1000px', overflow: 'auto' }}>
      <h3>RSVP:</h3>
      <table style={{ width: '930px' }} className="mb-8">
        <tbody>
          <tr>
            <td>
              <b>Daggasten</b>
            </td>
            <td>
              <b>Avondgasten</b>
            </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div>
                {headcountRsvpTotalDay} / {headcountTotalDay} + 2 kinderen
              </div>
            </td>
            <td>
              <div>
                {headcountRsvpTotalDay + headcountRsvpTotalEvening} / {headcountTotalDay + headcountTotalEvening}
              </div>
            </td>
            <td>
              <div
                onClick={() => {
                  sessionStorage.removeItem('guest_list');
                  window.location.reload();
                }}
                style={{ textDecoration: 'underline', color: 'green' }}
              >
                Refresh
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Daggasten:</h3>
      <table style={{ width: '1100px' }}>
        <tbody>
          <tr>
            <td>
              <b>Naam</b>
            </td>
            <td>
              <b>E-mail</b>
            </td>
            <td>
              <b>RSVP</b>
            </td>
            <td>
              <b>Dieet</b>
            </td>
            <td>
              <b>Comment</b>
            </td>
          </tr>
          {dayGuests.map((guest, index) => {
            return (
              <tr key={index} className={cn({ 'text--red': !Boolean(guest.rsvp) })}>
                <td>
                  <a
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                    href={`/guest/${guest.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {guest.name && getSaluteName(guest)}
                  </a>
                </td>
                <td>{guest.email && guest.email}</td>
                <td>
                  {guest.rsvp === _RSVP_OPTIONS.fullDay && '✅'}
                  {guest.rsvp === _RSVP_OPTIONS.fullDayNotNight && '✔'}
                  {guest.rsvp === _RSVP_OPTIONS.none && '❌'}
                </td>
                <td>{guest.diet && guest.diet}</td>
                <td>{guest.comments && guest.comments}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3>Avondgasten:</h3>
      <table style={{ width: '930px' }}>
        <tbody>
          <tr>
            <td>
              <b>Naam</b>
            </td>
            <td>
              <b>E-mail</b>
            </td>
            <td>
              <b>RSVP</b>
            </td>
          </tr>
          {eveningGuests.map((guest, index) => {
            return (
              <tr key={index} className={cn({ 'text--red': !Boolean(guest.rsvp) })}>
                <td>
                  <a
                    style={{ textDecoration: 'underline', color: 'inherit' }}
                    href={`/guest/${guest.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {guest.name && getSaluteName(guest)}
                  </a>
                </td>
                <td>{guest.email && guest.email}</td>
                <td>
                  {guest.rsvp === _RSVP_OPTIONS.evening && '✅'}
                  {guest.rsvp === _RSVP_OPTIONS.none && '❌'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h3>Avondgast toevoegen:</h3>
      <AddGuestForm />
    </div>
  );
};
