import React from 'react';
import '../style/guest.css';
import firebase from 'firebase/app';
import _get from 'lodash/get';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GuestSaveDate } from './GuestSaveDate';
import { EveningGuestSaveDate } from './EveningGuestSaveDate';
import { GuestRsvp } from './GuestRsvpForm';
import paths from '../config/paths';
import History, { pathWithId } from './History';
import { GuestRsvpAnswered } from './GuestRsvpAnswered';
import { Info } from './Info';
import { Menu } from './Menu';
import { AdminGate } from './adminGate';
import { EveningGuestRsvpAnswered } from './EveningGuestRsvpAnswered';
import { EveningGuestRsvp } from './EveningGuestRsvpForm';

const emailMessage = 'We hebben een geldig emailadres nodig';
const rsvpMessage = 'Geef een keuze op';

export class Guest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guest: {},
      rsvp: {
        has_plus_one: '',
        comments: '',
        diet: '',
        rsvp: '',
        rsvp_error: '',
        email: '',
        email_error: '',
      },
    };
  }

  getUserData = () => {
    const id = _get(this.props, 'match.params.id');
    if (!id || id.length !== 20) {
      this.setState({ loading: false });
      return;
    }
    const db = firebase.firestore();
    const app = this;
    db.collection('guest')
      .doc(id)
      .get()
      .then(function (doc) {
        if (!doc.data()) {
          return;
        }
        app.setState(
          {
            rsvp: {
              ...app.state.rsvp,
              ...doc.data(),
            },
            guest: {
              id,
              ...doc.data(),
            },
          },
          () => app.props.toggleLoading()
        );
      });
  };

  saveFields = () => {
    const isValid = this.validateFields();
    if (!isValid) {
      return;
    }
    const id = this.props.match.params.id;
    const answers = {
      email: this.state.rsvp.email,
      rsvp: this.state.rsvp.rsvp,
      diet: this.state.rsvp.diet,
      has_plus_one: this.state.rsvp.has_plus_one,
      comments: this.state.rsvp.comments,
    };
    const db = firebase.firestore();
    const app = this;
    db.collection('guest')
      .doc(id)
      .set(answers, { merge: true })
      .then(function () {
        app.setState({
          ...app.state,
          guest: {
            ...app.state.guest,
            ...answers,
          },
        });
        History.push(pathWithId(paths.guest.children.saveTheDate.path, id));
      });
  };

  updateFields = (event) => {
    this.setState({
      rsvp: {
        ...this.state.rsvp,
        [event.target.name]: event.target.value,
      },
    });
  };

  componentDidMount() {
    this.getUserData();
  }

  validateFields() {
    let emailError = '';
    let isValid = true;
    if (!/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.rsvp.email)) {
      emailError = emailMessage;
      isValid = false;
    }
    let rsvpError = '';
    if (!this.state.rsvp.rsvp) {
      rsvpError = rsvpMessage;
      isValid = false;
    }
    this.setState({
      rsvp: {
        ...this.state.rsvp,
        email_error: emailError,
        rsvp_error: rsvpError,
      },
    });
    return isValid;
  }

  render() {
    if (this.props.isLoading) {
      return null;
    }
    const guest = this.state.guest;
    return (
      <>
        <Menu id={this.props.match.params.id} isEvening={guest.type !== 'day'} />
        <Switch>
          <Route
            exact={true}
            path={paths.guest.children.saveTheDate.path}
            render={() => {
              if (guest.type === 'day') {
                return (
                  <>
                    {this.state.guest.rsvp && <GuestRsvpAnswered {...this.props} guest={guest} />}
                    {!this.state.guest.rsvp && <GuestSaveDate {...this.props} guest={guest} />}
                  </>
                );
              }
              return (
                <>
                  {this.state.guest.rsvp && <EveningGuestRsvpAnswered {...this.props} guest={guest} />}
                  {!this.state.guest.rsvp && <EveningGuestSaveDate {...this.props} guest={guest} />}
                </>
              );
            }}
          />
          <Route
            exact={true}
            path={paths.guest.children.rsvp.path}
            render={() => {
              if (guest.type === 'day') {
                return (
                  <GuestRsvp
                    guest={guest}
                    rsvp={this.state.rsvp}
                    updateFields={this.updateFields}
                    saveFields={this.saveFields}
                  />
                );
              }
              return (
                <EveningGuestRsvp
                  guest={guest}
                  rsvp={this.state.rsvp}
                  updateFields={this.updateFields}
                  saveFields={this.saveFields}
                />
              );
            }}
          />
          <Route exact={false} path={paths.guest.children.info.path} render={(props) => <Info guest={guest} />} />
          <Route exact={true} path={paths.guest.children.admin.path} render={(props) => <AdminGate guest={guest} />} />
          <Redirect to={paths.guest.children.saveTheDate.path} />
        </Switch>
      </>
    );
  }
}

export const getSaluteName = (guest) => {
  const { name, has_plus_one } = guest;
  let nameList = name.split('&');
  if (has_plus_one) {
    nameList = nameList.concat(has_plus_one.split(','));
  }
  if (nameList.length === 0) {
    return '';
  }
  const lastName = nameList.pop();
  if (nameList.length === 0) {
    return lastName;
  }
  return `${nameList.join(', ')} & ${lastName}`;
};
