import React from 'react';
import '../style/guest.css';
import { Field } from './Input';
import { _RSVP_OPTIONS } from '../global';
import { getSaluteName } from './Guest';

export const EveningGuestRsvp = ({ ...props }) => {
  return (
    <div className="guest-main-wrapper">
      <div className="form--wrapper delay-0">
        <span className="serif save-the-date--guest delay-0 fade-in">{getSaluteName(props.guest)}</span>
        <p className="delay-2 fade-in">
          We zouden het te gek vinden als je in de avond feest komt vieren. Geef hieronder wat info door:
        </p>
        <form>
          <div className="delay-2 fade-in">
            <Field
              value={props.rsvp.email}
              error={props.rsvp.email_error}
              name={'email'}
              label={'Email:'}
              onChange={props.updateFields}
            />
          </div>
          <div className="delay-4 fade-in mb-4">
            <Field
              value={props.rsvp.rsvp}
              error={props.rsvp.rsvp_error}
              name={'rsvp'}
              label={'Ben je erbij?'}
              type={'radio'}
              onChange={props.updateFields}
              options={[
                {
                  label: 'Natuurlijk!',
                  name: _RSVP_OPTIONS.evening,
                  value: _RSVP_OPTIONS.evening,
                },
                {
                  label: 'Helaas, ik kan niet.',
                  name: _RSVP_OPTIONS.none,
                  value: _RSVP_OPTIONS.none,
                },
              ]}
            />
          </div>
          <div className="delay-4 fade-in mb-8">
            <Field
              value={props.rsvp.has_plus_one}
              name={'has_plus_one'}
              label={'Neem je iemand mee?'}
              explain={'Geef namen komma gescheiden op'}
              onChange={props.updateFields}
            />
          </div>
          <div className="delay-6 fade-in">
            <button className="btn btn--primary" type="button" onClick={() => props.saveFields()}>
              Verstuur
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
