import React from 'react';
import '../style/guest.css';
import { Field } from './Input';
import firebase from 'firebase/app';

const defaultState = {
  name: '',
  added: {
    name: '',
    id: '',
  },
};

export const AddGuestForm = () => {
  const [state, setState] = React.useState(defaultState);
  const onChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const saveFields = async () => {
    const db = firebase.firestore();
    const response = await db.collection('guest').add({
      name: state.name,
      type: 'evening',
    });
    setState({
      name: '',
      added: {
        name: state.name,
        id: response.id,
      },
    });
  };

  return (
    <div>
      <form>
        <div className="delay-2 fade-in">
          <Field name={'name'} label={'name:'} value={state.name} onChange={onChange} />
        </div>
        {state.added.name && state.added.id && (
          <div
            class={'mb-4'}
            style={{
              background: '#5dad5d',
              color: 'white',
              padding: '.5em',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              Toegevoegd: <a href={`/guest/${state.added.id}`}>{state.added.name}</a>
            </div>
            <div
              onClick={() =>
                setState({
                  ...state,
                  added: defaultState.added,
                })
              }
            >
              &times;
            </div>
          </div>
        )}
        <div className="delay-6 fade-in">
          <button className="btn btn--primary" type="button" onClick={saveFields}>
            Verstuur
          </button>
        </div>
      </form>
    </div>
  );
};
