import * as React from 'react';
import sanityClient from '@sanity/client';

export const client = sanityClient({
  projectId: 'fpwjvx34',
  dataset: 'production',
  useCdn: true,
});

export const useSanityContent = () => {
  const [result, setResult] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const query = `*[_type == "content"]{ 
      _id,
      title,
      body,
    }`;
    setLoading(true);
    client.fetch(query).then((resp) => {
      setResult(resp);
      setLoading(false);
    });
  }, []);

  return [result, loading];
};
